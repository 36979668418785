/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import RxAttachment from '@/components/common/form/RxAttachment';
import RxUser from '@/components/common/form/RxUser';
import SysUser from '@/components/common/SysUser';
import rxCheckbox from '@/components/common/rxCheckbox';
import router from '@/router/index';
import { RxUtil } from "@/assets/util.js";
import { convertBoJson } from '@/assets/app.js';
Vue.component(RxAttachment.name, RxAttachment);
Vue.component(RxUser.name, RxUser);
Vue.component(SysUser.name, SysUser);
Vue.component(rxCheckbox.name, rxCheckbox);
export default {
  name: "rx-revokecommu",
  data() {
    return {
      opinion: "",
      taskId: ""
    };
  },
  mounted() {
    this.taskId = this.$route.params.taskId;
  },
  methods: {
    getNoticeType() {
      var url = _baseUrl + "/bpm/core/bpmConfig/getNoticeTypes.do";
      this.$ajax.get(url).then(response => {
        var rtn = response.data;
        this.noticeType = rtn;
      });
    },
    handRevoke() {
      if (!this.opinion) {
        this.errorToast("请填写意见!");
        return;
      }
      var paramObj = {
        taskId: this.taskId,
        opinion: this.opinion
      };
      var url = _baseUrl + "/bpm/core/bpmTask/revokeCommu.do";
      var params = RxUtil.serialJsonToForm(paramObj);
      this.$ajax.post(url, params).then(response => {
        var rtn = response.data;
        if (!rtn.success) {
          this.errorToast("撤销会签失败!");
          return;
        }
        this.successToast("撤销会签成功!", 500, "index");
      });
    }
  }
};